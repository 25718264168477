<template>
  <div>
    <Spinner v-if="isLoading" />
    <div v-else class="min-h-screen flex flex-col justify-between">
      <div class="mx-auto w-full max-w-3xl py-20 bg-white">
        <div v-if="error" class="text-center">{{ error }}</div>
        <Markdown v-else :source="markdown" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '@components/common/Footer'
import Markdown from '../common/Markdown'
import Spinner from '../common/Spinner'
import { getDocument } from '@platform-shared/services/document'

export default {
  components: { Markdown, Spinner, Footer },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      markdown: '',
      isLoading: false,
      error: '',
    }
  },
  watch: {
    title() {
      this.fetchDocument()
    },
  },
  created() {
    this.fetchDocument()
  },
  methods: {
    fetchDocument() {
      this.isLoading = true
      getDocument(this.title)
        .then((res) => {
          this.isLoading = false
          this.markdown = res.data
          this.error = ''
        })
        .catch(() => {
          this.isLoading = false
          this.error = this.$t('documents.error')
        })
    },
  },
}
</script>

<style></style>
