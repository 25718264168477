import api from './api'

/**
 * Get document from s3
 */
export const getDocument = (title) => {
  const getDocumentUrl = `/v1/${process.env.VUE_APP_DEVICE}/member/documents/${title}`
  return api()
    .url(getDocumentUrl)
    .get()
}
